// Gestion des paddings selon les navbars affichées
body {
  margin: 0;
  display: block;

  &.app-layout {
    &.mobile-tablet {
      padding-top: 135px; // 79 + 56
      padding-bottom: 56px; // 56
    }

    &.desktop {
      padding-top: 191px; // 79 + 56 + 56
      padding-bottom: 0;
    }

    .main-and-footer-container {
      min-height: calc(100vh - 191px); // 79 + 56 + 56
    }
  }

  &.simple-layout {
    padding-top: 79px;
    padding-bottom: 0;

    .main-and-footer-container {
      min-height: calc(100vh - 79px);
    }
  }
}

.main-and-footer-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.small-container {
  max-width: 800px;
}

