@import '1variables';
@import '2bootstrap-config';

@import "@ng-select/ng-select/themes/default.theme.css";
@import "bootstrap-icons/font/bootstrap-icons";
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

@import '3toaster';
@import "4layout";

// Mise en forme du ng-select pour ressembler aux composants Bootstrap 5
.ng-select {
  .ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
  }

  &.is-invalid .ng-select-container {
    border-color: $lgRed !important;
  }
}

button.btn-no-border {
  border: 1px transparent solid;
}

.form-switch {
  > input, > label {
    cursor: pointer;
  }
}

.date-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.accordion-button {
  padding-top: 5px;
  padding-bottom: 5px;
}

button.btn-camera {
  color: $lgRed;
  padding: 0;
  border: none;

  .bi-camera {
    font-size: 3rem;
  }
}

span.required {
  color: red;
  margin-left: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.spinner-md {
  width: 2rem;
  height: 2rem;
}

.spinner-lg {
  width: 3rem;
  height: 3rem;
}

ngb-datepicker-month {
  .ngb-dp-day {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ngb-dp-day, .ngb-dp-weekday {
    color: dimgray;
    position: relative;
    width: 3.3rem;
    height: 3rem;
  }
}

// Shadows provenant du livre Refactoring UI
.ru-shadow-1 {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
}

.ru-shadow-2 {
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2);
}

.ru-shadow-3 {
  box-shadow: 0 5px 15px hsla(0, 0%, 0%, 0.2);
}

.ru-shadow-4 {
  box-shadow: 0 10px 24px hsla(0, 0%, 0%, 0.2);
}

.ru-shadow-5 {
  box-shadow: 0 15px 35px hsla(0, 0%, 0%, 0.2);
}

.fade-in {
  animation: fade-in 1s;
}

.materiel-presentation {
  position: relative;
  min-height: 220px;

  &.loadingPhoto {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
  }

  img {
    &:not(.loadingPhoto) {
      animation: fade-in 1s;
    }

    width: 100%;
    border-radius: 0;
    max-height: 220px;
    object-fit: cover;
  }

  .over-badge-vgp {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 0 8px 0 8px;
    @extend .ru-shadow-1;
    display: flex;
    align-items: center;
    flex-direction: column;

    &.warning {
      color: #ffba64;
    }

    &.danger {
      color: $lgRed;
    }

    > i {
      font-size: 26px;
    }

    > span {
      margin-top: -10px;
    }
  }

  .over-badges {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > .over-badge {
      color: white;
      background-color: #00000096;
      border-radius: 5px;
      padding: 0px 5px 0px 5px;
      @extend .ru-shadow-1;
      margin-top: 5px;

      &.over-badge-numero {
        text-align: right;
        padding: 1px 5px 1px 5px;

        > .label-numero {
          color: #c7c7c7;
          font-size: 10px;
          margin-bottom: -5px;
        }
      }
    }
  }
}

.materiel-periode {
  width: 340px;
  display: flex;
  justify-content: space-around;
  align-items: baseline;

  .materiel-date {
    display: flex;
    @extend .ru-shadow-1;
    border-radius: 5px;
    min-width: 140px;

    .evenement-vert {
      background-color: #009B15;
      color: white;
    }

    .evenement-rouge {
      background-color: $lgRed;
      color: white;
    }

    > div:nth-child(1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 10px 10px 10px 10px;
    }

    > div:nth-child(2) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 10px 10px 10px 10px;
    }
  }

  .bi-arrow-right {
    font-size: 1.4rem;
  }
}

.page-menu {
  position: relative;
  margin-bottom: 10px;

  > button > span {
    display: none;
  }

  @include media-breakpoint-up(md) {
    > button > span {
      display: inline-block;
    }
  }

  > button:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
  }

  > div:nth-child(2) {
    font-weight: 500;
    font-size: 18px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  > button:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.form-control-composant {
  padding-right: 5px !important;
  padding-left: 5px !important;
  background-position-x: 95% !important;
}

.logo-locmat {
  color: $lgRed;
  font-size: 39px;
  font-family: Anton, sans-serif;
}

.list-group {
  > .list-group-item {
    min-height: 60px;
    display: flex;
    align-items: center;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
